export const getToweLabel = (towelSize: string) => {
  const size = towelSize.toLowerCase()
  switch (size) {
    case "30x30":
      return "Washcloth"
    case "30x50":
      return "Guest towel"
    case "50x80":
    case "50x90":
      return "Hand towel"
    case "70x140":
      return "Bath towel"
    case "100x150":
      return "Bath sheet"
    default:
      return size
  }
}
