import React from "react"
import styled from "@emotion/styled"
import { navigate } from "gatsby-link"
import { Box } from "theme-ui"

import loadable from "@loadable/component"

import { contentModulesReducer } from "../../../utils/reducers/reducers"
import { ContentfulTypes } from "../../../utils/types"

const Accordion = loadable(() => import("./Accordion"))
const BlogContent = loadable(() => import("./BlogContent"))
const RichText = loadable(() => import("../../new-ui/rich-text"))
const TileSection = loadable(() => import("../../new-ui/tile-section/index"))
const GlobalSizeGuide = loadable(() => import("./SizeGuide/global-size-guide"))
const Button = loadable(() => import("../../new-ui/button"))
const NavigationMenu = loadable(() => import("./navigation-menu"))

const AssetWrapper = loadable(() => import("../../new-ui/asset-wrapper"))
const SubscribeTileSection = loadable(() =>
  import("../../../components/subscribe-tile-section")
)

import handleReferenceLink from "../../../utils/functions/handleReferenceLink"
import { Shopstory } from "@shopstory/core/react"
import { TeklaShopstoryProvider } from "../../../shopstory/Provider"
import { ShopTheLook } from "../../v2/ShopTheLook"

const Content = styled(Box)`
  > * {
    margin-bottom: 2px !important;
  }

  .accordion {
    padding: 16px 0;
    width: 100%;
    justify-content: left;
    padding: 0 16px;
  }
`

const ContentModules = ({
  allModules,
  pullUp,
  onClick,
  collectionPath,
  sx,
  shopstoryContent,
  assetTitle,
}) => {
  const partitioned = contentModulesReducer(allModules)

  const module = (partitioned || []).map((s, index) => {
    switch (s.internal.type) {
      case ContentfulTypes.ContentfulBlogContent:
        return (
          <BlogContent
            key={s.id || index}
            input={s}
            padTop={index === 0 && pullUp}
          />
        )
      case ContentfulTypes.ContentfulNavigationMenu:
        return (
          <NavigationMenu
            key={s.id || index}
            input={s}
            padTop={index === 0 && pullUp}
          />
        )
      case ContentfulTypes.ContentfulRichText:
        return (
          <RichText
            key={s.id || index}
            input={s}
            padTop={index === 0 && pullUp}
            sx={s.sx}
            {...s}
          />
        )
      case ContentfulTypes.ContentfulTileSection:
        return s.title === "subscribe-jacquemus-tile-section" ||
          s.title === "subscribe-tile-section" ? (
          <SubscribeTileSection key={s.id || index} tileSection={s} {...s} />
        ) : (
          <TileSection
            key={s.id || index}
            tiles={s.tiles}
            padTop={index === 0 && pullUp}
            collectionPath={collectionPath}
            displayTitle={s.displayTitle}
            sx={s.sx}
            {...s}
          />
        )
      case ContentfulTypes.ContentfulAccordion:
        return (
          <Accordion
            key={s.id || index}
            input={s}
            padTop={index === 0 && pullUp}
          />
        )
      case ContentfulTypes.ContentfulGlobalSizeGuide:
        return <GlobalSizeGuide key={s.id || index} input={s} />

      case ContentfulTypes.ContentfulAssetWrapper:
        return (
          <AssetWrapper
            key={s.id}
            asset={s.asset}
            id={s.id}
            onClick={onClick}
            assetTitle={assetTitle}
            {...s}
          />
        )

      case ContentfulTypes.ContentfulButton:
        return (
          <Button
            key={s.id || index}
            onClick={() =>
              navigate(
                handleReferenceLink({
                  reference: s.reference,
                  locale: "en-US",
                })
              )
            }
            variant={s.type}
            sx={s.sx}
          >
            {s.label}
          </Button>
        )
      case ContentfulTypes.ContentfulShopstoryContent:
        return shopstoryContent?.[s.contentful_id] ? (
          <TeklaShopstoryProvider key={s.contentful_id}>
            <Shopstory {...shopstoryContent[s.contentful_id]} />
          </TeklaShopstoryProvider>
        ) : null
      case ContentfulTypes.ContentfulShopTheLook:
        return <ShopTheLook key={s.id} content={s} />
      default:
        return null
    }
  })

  return <Content sx={sx}>{module}</Content>
}

export default ContentModules
