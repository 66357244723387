import { EnrichedMedusaVariant } from "gatsby-node"

export const getMinimumPrice = (
  variants: EnrichedMedusaVariant[]
) => {
  if (!variants?.length) {
    return
  }

  return Math.min(
    // @ts-ignore
    ...variants?.map((i) => i && i.calculated_price_incl_tax)
  )
}
