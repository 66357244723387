import { EnrichedMedusaVariant } from "gatsby-node"

export const getMaximumPrice = (variants: EnrichedMedusaVariant[]) => {
  if (!variants?.length) {
    return
  }

  return Math.max(
    // @ts-ignore
    ...variants?.map((i) => i && i.calculated_price_incl_tax)
  )
}
