// External packages
import * as React from "react"
import { Box, Flex, Paragraph, Text } from "theme-ui"
import { createPortal } from "react-dom"

// Contexts
import { useStore } from "../../../context/NewStoreContext"

// Services
import { trackBeddingViewAllSizes } from "../../../services/analytics"

// Hooks
import useDrawerHook from "../../../hooks/useDrawerHook"

// Utilities
import { getSizingUnit } from "../utils/product/getSizingUnit"
import { isProductOutOfStock } from "../utils/product/isProductOutOfStock"
import { isVariantLowInStock } from "../utils/product/isVariantLowInStock"
import { isVariantOutOfStock } from "../utils/product/isVariantOutOfStock"
import { getPriceLabel } from "../utils/product/getPriceLabel"
import { getToweLabel } from "../utils/product/getTowelLabel"
import { handleAddToCart } from "../utils/product/handleAddToCart"
import {
  sizeToInches,
  sortVariants,
} from "../../../utils/functions/product-util"
import { isBedding } from "../../../utils/product-types"
import { isDimensionalSize } from "../../../utils/functions/product-util"

// Components
import { Drawer, DrawerProps } from "../ui/Drawer"
import { UiButton } from "../ui/Button"
import { Icon } from "../ui/Icon"
import { UiSpinner } from "../ui/Spinner"
import {
  SizePickerList,
  SizePickerListRow,
  SizePickerListViewAll,
} from "../ui/SizePickerList"
import SizeGuide from "../../ProductPage/SizeGuide"

// Types
import { EnrichedMedusaVariant } from "../../../../gatsby-node"

export interface SizePickerDrawerProps {
  variants: any
  product: any
  drawerProps: DrawerProps
  onAddToCart: (content: string) => void
  onNotifyMeClick: (variant: any) => void
  onSizeGuideCloseClick?: () => void
  onSizeGuideBackdropClick?: () => void
}

export const SizePickerDrawer: React.FC<SizePickerDrawerProps> = ({
  variants,
  product,
  drawerProps,
  onAddToCart,
  onNotifyMeClick,
  onSizeGuideCloseClick,
  onSizeGuideBackdropClick,
}) => {
  const { cart, createLineItem } = useStore()
  const sizingUnit = getSizingUnit(cart)
  const hasNotifyMe = !Boolean(product?.disableNotifyMe)

  const [hasExpandedSizes, setHasExpandedSizes] = React.useState<boolean>(false)

  const isNapkin = product?.type?.value?.toLowerCase() === "napkin"
  const isShoes =
    product?.sku?.startsWith("UJ-") ||
    (product?.sku?.startsWith("NA-") && !isNapkin)
  const hasVariantsSamePrice = variants?.every(
    (variant) =>
      variant.calculated_price_incl_tax ===
      variants[0].calculated_price_incl_tax
  )

  const sortedVariants: EnrichedMedusaVariant[] = React.useMemo(() => {
    return sortVariants(variants, isBedding(product))
  }, [variants, isBedding(product)])

  const visibleVariants =
    hasExpandedSizes || !isBedding(product)
      ? sortedVariants
      : sortedVariants.slice(0, 4)

  const recommendedInRegion =
    cart?.region?.metadata?.recommended_sizes?.[product?.type_id] || []

  const recommendedSizes: EnrichedMedusaVariant[] = sortedVariants.reduce(
    (acc, next) => {
      if (recommendedInRegion?.includes(next.title?.toLowerCase())) {
        acc.push(next)
      }
      return acc
    },
    []
  )

  const inventory = product?.variants?.reduce((acc, variant) => {
    acc[variant.id] = variant.inventory_quantity
    return acc
  }, {})

  const categoryName = product?.categories.filter(
    (i) => !i.parent_category_id
  )[0]?.name
  const collectionName = product?.categories.filter(
    (i) => i.parent_category_id
  )[0]?.name

  const handleProductButtonOnClick = (variant) => {
    if (hasNotifyMe && isVariantOutOfStock(variant)) {
      onNotifyMeClick(variant)

      return
    }

    if (
      !Boolean(variant?.id) &&
      ((isProductOutOfStock(inventory) && !hasNotifyMe) ||
        (!hasNotifyMe && isVariantOutOfStock(variant)))
    ) {
      return
    }

    handleAddToCart({
      product: {
        title: product?.title,
        subcategory: collectionName,
      },
      variant: {
        ...variant,
        quantity: 1,
      },
      onAddToCart: onAddToCart,
      cart: cart,
      createLineItem: createLineItem,
      quickAddToCart: true,
    })
  }

  const isSale = variants?.some((i) => i.calculated_price_type === "sale")
  const isDimensional = isDimensionalSize(product?.variants?.[0]?.title)

  // Size guide
  const {
    drawerContent: sizeGuideDrawer,
    drawerVisible: sizeGuideDrawerVisible,
    setDrawerVisible: setSizeGuideDrawerVisible,
    setDrawerContent: setSizeGuideDrawerContent,
  } = useDrawerHook({
    config: { asModal: false },
    onCloseClick: () => onSizeGuideCloseClick(),
    onBackdropClick: () => onSizeGuideBackdropClick(),
  })

  React.useEffect(() => {
    if (product?.size_guides?.length) {
      setSizeGuideDrawerContent(
        <SizeGuide
          sizeGuideGroups={product?.size_guides}
          initialSizing={
            cart?.shipping_address?.country_code?.toLowerCase() === "us"
              ? "inch"
              : "cm"
          }
          title={<h2>Size guide</h2>}
        />
      )
    }
  }, [cart?.region_id, product])

  React.useEffect(() => {
    createLineItem?.isSuccess && drawerProps.onCloseClick()
  }, [createLineItem?.isSuccess])

  return (
    <Drawer {...drawerProps}>
      <Flex
        sx={{
          fontSize: "md",
          justifyContent: "space-between",
          gap: 5,
          marginBlockEnd: 10,
        }}
      >
        <Paragraph sx={{ flexGrow: 1 }}>{product?.title}</Paragraph>
        {hasVariantsSamePrice && (
          <Paragraph sx={{ flexShrink: 0 }}>{product?.price}</Paragraph>
        )}
      </Flex>
      <Paragraph sx={{ marginBlockEnd: 3 }}>
        {recommendedSizes.length > 0
          ? `Sizes (${sizingUnit}) recommended based on your region:`
          : isBedding(product) || product?.sku?.startsWith("TT-")
          ? `Size (${sizingUnit}):`
          : isShoes
          ? "Size (EU):"
          : null}
      </Paragraph>
      <SizePickerList>
        <tbody>
          {visibleVariants.map((v) => {
            let size =
              cart?.region?.name === "United States" && isDimensional
                ? sizeToInches(v)
                : v.title

            if (
              product?.sku?.startsWith("TD-") &&
              product?.bundle_type === "pillowProduct"
            ) {
              size = size
            }

            const titleLowerCase = v.title?.toLowerCase()

            return (
              <SizePickerListRow
                key={v.id}
                label={
                  !hasVariantsSamePrice ? (
                    getPriceLabel(v, cart?.region?.currency_code)
                  ) : (
                    <></>
                  )
                }
                quickAddToCartContent={
                  <Flex
                    sx={{
                      height: "100%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {createLineItem?.variables?.variant_id === v.id &&
                      createLineItem?.isLoading && <UiSpinner />}

                    {Boolean(() => handleProductButtonOnClick(v)) &&
                    !isVariantOutOfStock(v) &&
                    (createLineItem?.variables?.variant_id !== v.id ||
                      !createLineItem?.isLoading) ? (
                      cart?.items.some((item) => item.variant_id === v.id) ? (
                        <Icon name="shopping-bag-filled" />
                      ) : (
                        <Icon name="shopping-bag" />
                      )
                    ) : createLineItem?.variables?.variant_id !== v.id ? (
                      <Text sx={{ color: "primary", whiteSpace: "nowrap" }}>
                        Notify me
                      </Text>
                    ) : null}
                  </Flex>
                }
                tagChildren={
                  isSale ? (
                    <></>
                  ) : isVariantLowInStock(v) ? (
                    `${v.inventory_quantity} left in stock`
                  ) : (
                    <></>
                  )
                }
                tagProps={{
                  color: "primary",
                  sx: {
                    display: isVariantLowInStock(v) ? "inline-block" : "none",
                  },
                }}
                isDisabled={isVariantOutOfStock(v)}
                isHoverDisabled={true}
                onClick={() => {
                  Boolean(() => handleProductButtonOnClick(v)) &&
                  !isVariantOutOfStock(v) &&
                  (createLineItem?.variables?.variant_id !== v.id ||
                    !createLineItem?.isLoading)
                    ? handleProductButtonOnClick(v)
                    : createLineItem?.variables?.variant_id !== v.id
                    ? onNotifyMeClick(v)
                    : null
                }}
                sx={{ cursor: "pointer" }}
              >
                {v.sku?.startsWith("TT-") ? (
                  <>
                    {getToweLabel(titleLowerCase)} ({titleLowerCase})
                  </>
                ) : (titleLowerCase === "270x235" ||
                    titleLowerCase === "193x203x40" ||
                    (titleLowerCase === "50x90" &&
                      product?.title?.toLowerCase().includes("pillow"))) &&
                  cart?.region?.name === "United States" ? (
                  "King"
                ) : (titleLowerCase === "230x235" ||
                    titleLowerCase === "153x203x40" ||
                    (titleLowerCase === "50x70" &&
                      product?.title?.toLowerCase().includes("pillow"))) &&
                  cart?.region?.name === "United States" ? (
                  "Queen"
                ) : titleLowerCase === "179x226" &&
                  cart?.region?.name === "United States" ? (
                  "Twin / XL"
                ) : (
                  size
                )}
              </SizePickerListRow>
            )
          })}
        </tbody>
      </SizePickerList>
      {!hasExpandedSizes &&
      isBedding(product) &&
      (sortedVariants.length > 4 ||
        recommendedSizes.length < sortedVariants.length) ? (
        <SizePickerListViewAll
          sideContent={<Text sx={{ fontSize: "lg", lineHeight: 1 }}>+</Text>}
          onClick={() => {
            setHasExpandedSizes(true)
            trackBeddingViewAllSizes(product, collectionName)
          }}
        >
          View all sizes
        </SizePickerListViewAll>
      ) : null}
      {product?.size_guides?.length ? (
        <Box
          sx={{
            backgroundColor: "grayscale.white",
            position: "sticky",
            bottom: 0,
            paddingBlockEnd: 10,
            marginBlockEnd: -10,
          }}
        >
          <UiButton
            variant="secondary"
            onClick={() => setSizeGuideDrawerVisible(true)}
            sx={{ width: "100%", marginBlockStart: 12 }}
          >
            Size guide
          </UiButton>
        </Box>
      ) : null}
      {createPortal(
        <>{sizeGuideDrawer}</>,
        document.getElementById("drawers-root")
      )}
    </Drawer>
  )
}
