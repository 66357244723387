
import { formatMoneyAmount } from "../../../../utils/prices"
import { Text } from "theme-ui"
import { EnrichedMedusaVariant } from "gatsby-node"

export const getPriceLabel = (
  variant: EnrichedMedusaVariant,
  currencyCode: string
) => {
  if (!variant || !currencyCode) return null
  const originalPrice = variant?.original_price_incl_tax
  const calculatedPrice = variant?.calculated_price_incl_tax

  const originalPriceFormatted = formatMoneyAmount(
    {
      currencyCode: currencyCode,
      amount: originalPrice,
    },
    0
  )

  if (
    variant.calculated_price_type === "sale" &&
    calculatedPrice < originalPrice
  ) {
    const calculatedPriceFormatted = formatMoneyAmount(
      {
        currencyCode: currencyCode,
        amount: calculatedPrice,
      },
      0
    )

    return (
      <>
        <Text
          sx={{
            textDecoration: "line-through",
            color: "grayscale.500",
            marginInlineEnd: 2,
          }}
        >
          {originalPriceFormatted}
        </Text>
        <Text>{calculatedPriceFormatted}</Text>
      </>
    )
  }

  return originalPriceFormatted
}
